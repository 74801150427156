import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';
import * as React from 'react';
import { metaAdder } from '../common/helpers/meta.adder';

const classes = {
  ul: 'ul',
  p: 'p',
  alert: 'alert'
};

const Root = styled('div')({
  [`& .${classes.ul}`]: {
    fontSize: 16,
  },
  [`& .${classes.p}`]: {
    fontSize: 16,
  },
  [`& .${classes.alert}`]: {
    fontSize: 16,
    fontWeight: 700,
  },
});

const Purchases: React.FC = () => {
  React.useEffect(() => {
    document.title = 'Покупаем неликвиды Electroprom';
    metaAdder(`name="description"`, 'Electroprom - на постоянной основе покупаем неликвиды и складские остатки - силовые диоды, тиристоры, симисторы, оптотиристоры, модули, оптотиристоры');
    metaAdder(`name="keywords"`, 'Electroprom, Электропром Украина, Электропром, Электропром Запорожье, Эл-пром, El-prom, Electroprom Украина, купим силовые диоды, купим тиристоры, купим симисторы, купим оптотиристоры, купим силовые модули, купим оптотиристоры, купим IGBT');
  }, []);
  return (
    <Root>
      <h1>Покупаем:</h1>
      <Alert severity="info" className={classes.alert}>
        На постоянной основе покупаем силовые диоды, тиристоры, симисторы, оптотиристоры, модули, оптотиристоры:
      </Alert>
      <ul className={classes.ul}>
        <li>новые и бу;</li>
        <li>отечественного и импортного производства (Орел, Саранск, Запорожье, Китай, Estel, Semikron, Infinion, Eupec и др..)</li>
        <li>Т112-10, Т112-16, Т122-20, Т122-25, Т132-40, Т132-50, Т132-63, Т142-63, Т142-80, Т152-80, Т152-125, Т152-160;</li>
        <li>ТС112-10, ТС112-16, ТС122-25, ТС132-40, ТС132-50, ТС132-63, ТС142-80, ТС152-160;</li>
        <li>ТО115, ТО125-10, ТО125-12.5, ТО325-12.5, ТО132-25, ТО132-40, ТО142-80;</li>
        <li>Т123-200, Т123-250, Т123-320;</li>
        <li>Т133-320, Т133-400, Т233-320, Т233-400;</li>
        <li>Т143-400, Т143-500, Т143-630, Т143-800, Т243-400, Т243-500, Т243-630, Т243-800;</li>
        <li>Т153-630, Т153-800, Т153-1000, Т253-800, Т253-1000, Т253-1250, Т353-800, Т353-1000;</li>
        <li>Т173-1250, Т173-1600, Т173-2000;</li>
        <li>ТБ133-200, ТБ133-250, ТБ233-400, ТБ233-500, ТБ143-320, ТБ143-400, ТБ143-500, ТБ143-630, ТБ243-500, ТБ243-630, ТБ(ТБИ)153-1000;</li>
        <li>Т151-100, Т161-125, Т161-160, Т161-200, ТС161-160, ТС161-200;</li>
        <li>Т(ТЛ)171-250,320; Т(ТЛ)271-250,320; ТС 171-250, ТС 171-320;</li>
        <li>Д(ДЛ)112-10, Д(ДЛ)112-16, Д(ДЛ)112-25;</li>
        <li>Д(ДЛ)122-25, Д(ДЛ)122-32, Д(ДЛ)122-40;</li>
        <li>Д(ДЛ)132-50, Д(ДЛ)132-63, Д (ДЛ) 132-80;</li>
        <li>Д(ДЛ)142-80, Д (ДЛ) 142-100, Д141-100;</li>
        <li>Д151-160, Д152-160, Д(ДЛ)161-200, 250, 320;</li>
        <li>Д(ДЛ)171-320, Д(ДЛ)171-400;</li>
        <li>Д123-500, ДЛ123-320;</li>
        <li>Д133-400, Д133-500, Д133-630, Д133-800, ДЛ133-500, ДЛ133-800;</li>
        <li>Д143-630, Д143-800, Д143-1000, Д143-2000, ДЛ243-800, ДЛ243-1000;</li>
        <li>Д(ДЛ)153-1250,1600; Д(ДЛ)153-2000, Д(ДЛ)253-1600;</li>
        <li>В(ВЛ)10, В(ВЛ)25, В(ВЛ)50, В(ВЛ)200, В320, В500, В800;</li>
        <li>Т25, Т50, Т160, ТЛ2-160, ТЛ2-200, ТЛ4-250;</li>
        <li>ТЧ63, ТЧ80, ТЧ125 ТЧИ100, ТЧИ125;</li>
        <li>Модули МТТ, МДД, МДТ, МТД, МТОТО, МДТО, TT, SKKT, SKKD, SKKH, МГТСО;</li>
        <li>охладители О111, О221, О231, О241, О151, О171, О181, О281, О143, О 153, О253 и другие;</li>
        <li>шунты 75ШССМ3-0,5 10А, 20А, 30А, 50А, 75А, 100А, 150А, 200А, 300А, 500А, 750А, 1000А, 1500А, 2000А, 3000А, 4000А, 5000А, 6000А и другие компонеты.</li>
      </ul>
      <Alert severity="warning" className={classes.alert}>
        Просьба предложения отправлять на почту <a href="mailto:reznik333@gmail.com">reznik333@gmail.com</a> или на Вайбер, Телеграмм <a href="tel:+380677201352">+380677201352</a> с фото, описанием состояния и ценой.
      </Alert>
    </Root>
  );
};

export default Purchases;
